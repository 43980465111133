import React from 'react';

export default function Slider (){
    return (
        <header id="home" className="header">
            <div className="overlay"></div>
            <div className="header-content container">
                <h1 className="header-title">
                    <span className="up">HI!</span>
                    <span className="down">I am Murtuza Hussain</span>
                </h1>
                <p className="header-subtitle">FULLSTACK Developer!</p>

                <button className="btn btn-primary">Visit My Works</button>
            </div>              
        </header>
    );
}