import React from 'react';
import Slider from './compnents/Slider';
import Navigation from './compnents/Navigation';
import { About } from './compnents/About';
import Services from './compnents/Services';
import Footer from './compnents/Footer';
import Contact from './compnents/Contact';
import Blog from './compnents/Blog';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <Slider/>
      <About />
      <Services />
      <Blog />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
