import { useState } from 'react';

export default function Header() {
    const [isShow, setShow] = useState(true);


    return (
        <nav className="custom-navbar" data-spy="affix" data-offset-top="20">
            <div className="container">
                <a className="logo" href="murtuza.dev">Murtuza.deV</a>
                <ul className={isShow ? "nav" : "nav show"}>
                    <li className="item">
                        <a className="link" href="#home">Home</a>
                    </li>
                    <li className="item">
                        <a className="link" href="#about">About</a>
                    </li>
                    <li className="item">
                        <a className="link" href="#portfolio">Portfolio</a>
                    </li>
                    <li className="item">
                        <a className="link" href="#testmonial">Testmonial</a>
                    </li>
                    <li className="item">
                        <a className="link" href="#blog">Blog</a>
                    </li>
                    <li className="item">
                        <a className="link" href="#contact">Contact</a>
                    </li>
                </ul>
                <a href='void()'
                    onClick={(e) => {
                        e.preventDefault();
                        e.currentTarget.classList.toggle('is-active');
                        setShow(!isShow);
                    }}
                    id="nav-toggle" className="hamburger hamburger--elastic" aria-label={'X'} arial-role={'menu'} role='menu'>
                    <div className="hamburger-box">
                        <div className="hamburger-inner"></div>
                    </div>
                </a>
            </div>
        </nav>
    );
}
