import React from 'react'

export default function Footer() {
    let year = new Date().getFullYear();
  return (
    <div className="container">
        <footer className="footer">       
            <p className="mb-0">Copyright {year} &copy; <a href="https://murtuza.com">Murtuza</a></p>
            <div className="social-links text-right m-auto ml-sm-auto">
                <a href="https://facebook.com/M.Murtuza.H" className="link ml-1" aria-describedby='facebook' title='facebook'><i className="ti-facebook"></i></a>
                <a href="https://twitter.com/mmurtuzah" className="link ml-1" aria-describedby='facebook' title='facebook'><i className="ti-twitter-alt"></i></a>
                <a href="https://www.instagram.com/m.murtuza.h/" className="link ml-1" aria-describedby='facebook' title='facebook'><i className="ti-instagram"></i></a>
                <a href="https://github.com/mmurtuza" className="link ml-1" aria-describedby='facebook' title='facebook'><i className="ti-github"></i></a>
            </div>
        </footer>
    </div>
  )
}
