import React from 'react'
import murtuza from '../assets/imgs/myAvatar.png'

export const About = () => {
  return (
    <section className="section pt-0" id="about">
      <div className="container text-center">
        <div className="about">
          <div className="about-img-holder">
            <img src={murtuza} className="about-img" alt="Murtuza Hussain" />
          </div>
          <div className="about-caption">
            <p className="section-subtitle">Who Am I ?</p>
            <h2 className="section-title mb-3">About Me</h2>
            <p>
              I am very quality-oriented. I am familiar with many modern frameworks as mentioned. I am a creative-thinker, Very Quick Learner, hard-working with experience in vanilla JS, Laravel and Flutter to successfully fill the requirement of this Job. I am also able
              to easily learn new programming languages and Frameworks and
              can solve new and hard problems. Efficiently makes me a strong
              contender for the job.
              <br />
            </p>
            <a href="https://murtuza.dev/Resume.pdf" download="Resume.pdf" className="btn-rounded btn btn-outline-primary mt-4">Download CV</a>
          </div>
        </div>
      </div>
    </section>
  )
}
